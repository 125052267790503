import React from "react"

import Layout from "../components/layout"
import { Col, Container, Row } from "react-bootstrap"
import { PageContainer } from "../components/PageContainer/styles";

const ContactPageError = () => {

  return (<Layout>
    <PageContainer>
      <Container>
        <Row>
          <Col xs={12} className="mb-5">
            <p className="mb-3">Ci dispiace, ma a causa di un disservizio del sistema la tua richiesta <span className="text-danger"><strong>non è stata correttamente inviata</strong></span></p>
            <p>Ti chiediamo cortesemente di contattarci per via telefonica, sms o messaggio <a href="https://wa.me/393397581130" target="_blank" className="whatsapp-link">whatsapp</a>:</p>
            <a href="tel:+393397581130">
              3397581130
            </a>
            <p className="mt-3"><strong>Ci scusiamo</strong> nuovamente per il disagio</p>
          </Col>
        </Row>
      </Container>
    </PageContainer>
  </Layout>)
}

export default ContactPageError
